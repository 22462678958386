import Head from 'next/head';
import { FC } from 'react';

// import CSP from '../../config/next.csp.config';

interface MetaBlockProps {
    title: string;
    description: string;
    keywords?: string;
    leadImage?: string;
    children?: React.ReactNode;
}

const MetaBlock: FC<MetaBlockProps> = (props) => {
    const { title, description, leadImage, keywords, children } = props;

    return (
        <Head>
            <meta charSet="utf-8" />
            {/* <meta httpEquiv="Content-Security-Policy" content={CSP} /> */}
            {/* <meta name="google-site-verification" content="ZJMu9MsvjHJwPn0kcq31cioCrPxAw0HHWqWOqXpq1-Y" /> */}

            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />

            {keywords && (
                <>
                    <meta name="keywords" content={keywords} />
                </>
            )}
            {leadImage && (
                <>
                    <meta property="og:image" content={leadImage} />
                    <meta property="twitter:image" content={leadImage} />
                </>
            )}
            {children}
        </Head>
    );
};

export default MetaBlock;
