import { ThemeStyles, useThemeContext } from '@/contexts/ThemeContext';
import { useEffect } from 'react';

import Button from '../button';
import styles from './not-found.module.scss';

const NotFound = () => {
    const theme = useThemeContext();

    useEffect(() => {
        theme.setThemeStyle(ThemeStyles.Light);
    }, [theme]);

    return (
        <div className={styles.pageNotFound}>
            <div className={styles.number}>404</div>
            <p className={styles.info}>Oops! The page you are looking for doesn’t exist. Try the button below...</p>
            <a href={'/'} className={styles.buttonBack} aria-label="Back to home">
                <Button>Back to home</Button>
            </a>
        </div>
    );
};

export default NotFound;
